export const IMAGES_PATH = process.env.PUBLIC_URL + "/Images/HomePage";
export const IMAGES_PATHTWO = process.env.PUBLIC_URL + "/Images/HomeSecond";
export const IMAGES_PATH_FOOTER = process.env.PUBLIC_URL + "/Images/Footer";
export const IMAGES_PATH_DOWNLOAD = process.env.PUBLIC_URL + "/Images/Download";
export const IMAGES_PATH_POPUP = process.env.PUBLIC_URL + "/Images/PopUP";
export const IMAGES_PATH_BLOGS = process.env.PUBLIC_URL + "/Images/Bolgs";
export const IMAGES_PATH_DIGITAL_PAYMENT =
  process.env.PUBLIC_URL + "/Images/DigitalPayment";
export const IMAGES_PATH_TUTOEIAL = process.env.PUBLIC_URL + "/Images/Tutorial";
export const IMAGES_PATH_NAVBAR = process.env.PUBLIC_URL + "/Images/NavBar";
export const IMAGES_PATH_OURPARTNERS =
  process.env.PUBLIC_URL + "/Images/OurPartners";
